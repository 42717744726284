@import '/Theme';
@import './Navigation';
@import './HeaderBar';
@import './Loading';
@import './Channel';
@import 'ContentEditor';
@import './Content';
@import './User';
@import './System';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;

  #root {
    width: 100%;
    height: 100%;
  }

  #App {
    width: 100%;
    height: 100vh;
  }

  .Page {
    width: 100%;
    height: 100%;

    .PageContent {
      padding: $primary_padding $primary_padding $super_padding $primary_padding;

      .PageTitle {
        background-colour: 'red';
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $primary_margin;

        .TitleContent {
          display: flex;
          align-items: center;
          h1 {
            margin: 0 $primary_margin 0 0 !important;
          }
        }


        .MetaCount {
          display: flex;
          align-items: center;

          svg {
            font-size: 1.5em;
            margin-right: $secondary_margin;
          }
        }
      }
    }

    .PageSidebar {
      background-color: $off_white_colour;
      padding: $primary_padding $primary_padding $super_padding $primary_padding;
      border-top: 1px solid $tertiary_colour;

      .PageSidebarActions {
        display: flex;
        flex-direction: column;

        .PageSidebarAction {
          margin-top: $secondary_margin;
        }

        .PageSidebarDelete {
          margin-top: $secondary_margin;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    #App {
      display: flex;
    }

    .Wrapper {
      display: flex;
      width: 100%;
      height: 100vh;
    }

    .Main {
      flex-grow: 5;

      display: flex;
      flex-direction: column;
    }

    .Page {
      display: flex;
      height: calc(100vh - 75px);

      .PageContent {
        flex-grow: 2;
        overflow: auto;

        .PageContentHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h1 {
            margin: 0;
          }
        }
      }

      &.PageEditor {
        .PageSidebar {
          width: 240px;
          min-width: 240px;
          height: 100%;
          overflow-y: auto;

          border: none;
          border-left: 1px solid $tertiary_colour;

          .PageSidebarActions {
            align-items: flex-end;
          }
        }
      }
    }
  }
}
