@import '/Theme';

.ChannelAppearanceWrapper {
  display: flex;
  flex-direction: column;

  .ChannelAvatar {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 20px;
  }

  .ChannelAvatarPreview {
    width: 200px;
    height: 200px;

    background-color: $tertiary_colour;
    border: 1px solid $tertiary_colour;
    background-size: cover;
    background-position: 50% 50%;

    margin-bottom: 20px;
  }

  .ChannelBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ChannelBannerPreview {
    width: 350px;
    height: 97px;

    background-color: $tertiary_colour;
    border: 1px solid $tertiary_colour;
    background-size: cover;
    background-position: 50% 50%;

    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .ChannelAppearanceWrapper {
    display: flex;
    flex-direction: row;

    .ChannelAvatar {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 0;
      margin-right: 20px;
    }

    .ChannelBannerPreview {
      width: 500px;
      height: 138px;
    }
  }
}

.CategoryLogoWrapper {
  display: flex;
  flex-direction: column;

  width: 200px;

  .CategoryLogoPreview {
    width: 200px;
    height: 200px;

    background-color: $tertiary_colour;
    border: 1px solid $tertiary_colour;
    background-size: cover;
    background-position: 50% 50%;

    margin-bottom: 20px;
  }

  .CategoryLogoActions {
    display: flex;
    flex-direction: column;

    > .button.primary {
      margin-bottom: $secondary_margin;
    }
  }
}
