@import '/Theme';

aside.Navigation {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 200px;

  background: $primary_colour;
  color: $white_colour;
  font-weight: 500;

  padding: $primary_padding;

  img {
    padding: 40px;
  }

  .NavigationItem {
    padding-bottom: $secondary_padding;
  }

  .NavigationDivider {
    border: none;
    margin: $tertiary_margin 0;
  }

  .NavigationLinks {
    flex-grow: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }

  &.MobileNavigation {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;

    .MobileNavigationClose {
      position: absolute;
      top: 30px;
      left: 20px;

      font-size: 2em;
    }

    .NavigationItem {
      font-size: 1.25em;
      padding-bottom: 20px;
    }
  }
}
