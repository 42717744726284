@import '/Theme';

.HeaderBar {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 75px;

  background: $secondary_colour;

  padding: $secondary_padding $primary_padding;

  .NavigationWrapper {
    flex-grow: 2;

    display: flex;

    .BackButton, .MobileNavButton {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 38px;
      height: 38px;

      background: $white_colour;
      border-radius: 5px;
      margin-right: $primary_margin;

      transition: opacity 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      i {
        font-size: 1.2em;
        color: $secondary_colour;
        margin: 0;
      }
    }

    @media only screen and (max-width: 767px) {
      .BackButton {
        display: none;
      }
    }

    @media only screen and (min-width: 768px) {
      .MobileNavButton {
        display: none;
      }
    }

    .WideSearchWrapper {
      position: relative;
      width: 250px;
      max-width: 100%;
      transition: width 0.5s;

      &.active {
        width: 800px;
      }

      @media only screen and (max-width: 767px) {
        width: 215px;

        &.active {
          width: 215px;
        }
      }

      .input.active {
        input {
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-bottom: none !important;
        }
      }

      .WideSearchResultsWrapper {
        position: absolute;
        z-index: 99;

        width: 100%;

        padding: $secondary_padding $secondary_padding $primary_padding;

        background: $white_colour;
        border: 1px solid $tertiary_colour;
        border-top: none;
        border-bottom-left-radius: 0.28571429rem;
        border-bottom-right-radius: 0.28571429rem;

        .WideSearchResultsItems {
          display: flex;
          overflow-x: auto;

          .WideSearchResultItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100px;

            text-align: center;
            transition: opacity 0.3s;

            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }

            .WideSearchResultItemImage {
              width: 50px;
              height: 50px;
              border-radius: 50%;

              background-color: $tertiary_colour;
              background-size: cover;
              background-position: 50% 50%;

              margin-bottom: $secondary_margin;
            }
          }
        }
      }
    }
  }

  .LoggedInUser {
    display: flex;
    align-items: center;

    .ui.dropdown .menu .active.item {
      font-weight: 400;
    }

    .LoggedInUserAvatar {
      width: 45px;
      height: 45px;

      background: $tertiary_colour;
      background-size: cover;
      border-radius: 50%;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
