// Colours

$primary_colour: #000;
$secondary_colour: #181919;
$tertiary_colour: #22242626;
$alt_colour: #616161;
$action_colour: #0091ff;
$warning_colour: #f1c40f;
$border_colour: #979797;
$danger_colour: #db2828;

$white_colour: #fff;
$off_white_colour: #f9fafb;

// Spacing

$super_padding: 40px;
$primary_padding: 20px;
$secondary_padding: 10px;
$tertiary_padding: 5px;
$super_margin: 40px;
$primary_margin: 20px;
$secondary_margin: 10px;
$tertiary_margin: 5px;

// Typography

h1 {
  font-size: 1.4em;
  font-weight: 700;
}

h2 {
  font-size: 1.2em;
  font-weight: 700;
}

h3 {
  font-size: 1em;
  font-weight: 700;
}

.small-type {
  font-size: 0.85em;
}

.normal-type {
  font-weight: normal;
}

.bold-type {
  font-weight: 700;
}

.primary-colour-type {
  color: $primary_colour;
}

.secondary-colour-type {
  color: $secondary_colour;
}

.tertiary-colour-type {
  color: $tertiary_colour;
}

.alt-colour-type {
  color: $alt_colour;
}

.danger-colour-type {
  color: $danger_colour;
}

a {
  color: $white_colour;
  text-decoration: none;
}

a:hover {
  color: $action_colour;
}

// Inputs

.SectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: $secondary_margin;

  h2 {
    margin: 0;
  }

  svg {
    font-size: 1.5em;
    color: $warning_colour;
  }
}

.LangStringSelector {
  &:hover {
    cursor: pointer;
  }
}

.InputLink {
  margin-top: $secondary_margin;

  a {
    color: $action_colour;

    font-weight: bold;
    font-size: 0.85em;

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}

// Datepicker

.react-datepicker-wrapper {
  width: 100% !important;
}

// Table

.SubTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }
}

.Table {
  border-radius: 5px;
  overflow: hidden;

  .TableHeader {
    background-color: $off_white_colour;
    border-bottom: 1px solid $tertiary_colour;
    font-weight: bold;
  }

  .TableHeaderDateLabel {
    background-color: $tertiary_colour;
    font-weight: bold;
  }

  .TableRowClickable {
    &:hover {
      cursor: pointer;
    }
  }

  .PaginationCell {
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;
  }

  .TableImage {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    width: 40px;
    height: 40px;
    margin: 0 auto;

    background-color: $tertiary_colour;
    background-size: cover;
    background-position: 50% 50%;

    border-radius: 50%;

    .TableImageModerationFlag {
      width: 14px;
      height: 14px;

      background-color: $warning_colour;
      border: 1px solid $white_colour;
      border-radius: 50%;

    }
  }
}

// Filter

.Filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: $primary_margin 0 $super_margin;

  > div {
    max-width: 600px;
  }

  .FilterModeration {
    margin-top: $primary_margin;
  }
}

.FilterFullWidth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.FilterRow {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  > div {
    width: 450px;
  }
}

.FilterRowFullWidthCenter {
  display: flex;
  justify-content: center;
}

// Highlight

.HighlightCurrent {
  margin-bottom: $primary_margin;

  .HighlightLabel {
    background: $tertiary_colour;
    font-weight: 700;
    padding: $tertiary_padding $secondary_padding;
    border-radius: 5px;

  }
}

// Modal {
.FileDrop {
  margin-bottom: $primary_margin;
}

// Dropdowns
.ui.search.dropdown>.text, .ui.selection.dropdown .menu>.item {
  display: flex;
}


