@import '/Theme';

.LoadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: $primary_colour;
}
