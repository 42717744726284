@import '/Theme';

.ContentType, .FeatureType {
  .ContentTypeInputs {
    margin-bottom: $primary_margin;
  }

  .ContentTypeThumbnail {
    width: 200px;
    height: 200px;

    background-color: $tertiary_colour;
    border: 1px solid $tertiary_colour;
    background-size: cover;
    background-position: 50% 50%;

    margin-bottom: 20px;
  }

  .ContentTypeThumbnailActions {
    display: flex;
    flex-direction: column;

    > .button.primary {
      margin-bottom: $secondary_margin;
    }
  }

  .FeatureTypeMediaCover {
    width: 393px;
    height: 223px;

    background-color: $tertiary_colour;
    border: 1px solid $tertiary_colour;
    background-size: cover;
    background-position: 50% 50%;

    margin-bottom: 20px;
  }

  .FeatureTypeMediaCoverActions {
    display: flex;
    flex-direction: column;

    max-width: 393px;

    > .button.primary {
      margin-bottom: $secondary_margin;
    }
  }
}



@media only screen and (min-width: 1024px) {
  .ContentType, .FeatureType {
    display: flex;

    .ContentTypeInputs {
      flex-grow: 2;

      margin-right: $primary_margin;
    }
  }
}
