@import '/Theme';

.SponsorLogoWrapper {
  display: flex;
  flex-direction: column;

  width: 400px;

  .SponsorLogoPreview {
    width: 400px;
    height: 140px;

    background-color: $tertiary_colour;
    border: 1px solid $tertiary_colour;
    background-size: cover;
    background-position: 50% 50%;

    margin-bottom: 20px;
  }

  .SponsorLogoActions {
    display: flex;
    flex-direction: column;

    > .button.primary {
      margin-bottom: $secondary_margin;
    }
  }
}
