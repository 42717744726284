@import '/Theme';

.UserAppearanceWrapper {

  .UserAvatar {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
  }

  .UserAvatarPreview {
    width: 200px;
    height: 200px;

    background-color: $tertiary_colour;
    border: 1px solid $tertiary_colour;
    background-size: cover;
    background-position: 50% 50%;

    margin-bottom: 20px;
  }
}
